import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from 'react'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { sendAPIRequest } from '../../../components/src/utils'
import { getStorageData } from '../../../framework/src/Utilities';

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    token: string;

    selectedMyTab: number;
    modal: 'transfer' | 'deficient' | null;
    loadingServices: boolean;
    filterQuery: string;
    randomServiceId?: string;
    getServiceDetailCallId?: string;
    service?: any;
    submittingForm?: boolean;
    moderatorSearchCallId: string;
    requestorSearchCallId: string;
    taggedComSearchCallId:string;
    taggedProjectSearchCallId:string;
    moderatorOptions: any[];
    requestorOptions: any[];
    taggedComOptions:any[];
    taggedProjectOptions:any[];
    moderatorPagination?: any;
    requestorPagination?: any;
    taggedComPagination?:any;
    taggedProjectPagination?:any;
    moderatorPaginateCallId?: any;
    requestorPaginateCallId?: any;
    taggedComPaginateCallId?:any;
    taggedProjectPaginateCallId?:any;
    moderatorQuery: any;
    requestorQuery: any;
    taggedComQuery: any;
    taggedProjectQuery: any;
    allUserOptions: any[],
    allRequestorOptions: any[],
    allTaggedComOptions:any[],
    allTaggedProjectOptions:any[],
    memberOptions: any[],
    memberRequestorOptions: any[],
    memberTaggedComOptions:any[];
    memberPagination?: any;
    requestorMemberPagination?: any;
    taggedComMemberPagination?:any;
    taggedProjectMemberPagination?:any;
    memberSearchCallId: string;
    memberPaginateCallId?: any;
    selectedItem?: any;
    loadingCorpstageCoins: boolean,
    corpstageWallet: any,
    totalSelectedCoins: number;
    coinErrors: any[];
    importLoading: boolean;
    importedUsers: any[];
    transferData: any[];
    enableBtn: boolean;
    disableBtn: boolean;
    totalCoins: any;
    selectedCountCoins: { id: number, coins: number }[];
    template_id: string;
    requested_by: any[];
    status: any;
    inactive_date: any;
    start_date: any;
    end_date: any;
    template_name: string;
    survey_title: string;
    description: string;
    survey_time: number | null;
    reminder_duration: number | null;
    reminder_type: any;
    reminder_frequency: boolean;
    tagged_communities: any;
    tagged_projects: any;
    tagged_users: any;
    tagged_users_obj: any;
    validEndDate: any;
    toggleDisabled:any;
    taggedUserIds: number[];
    // getSurveyEditCallId:any;
    // surveyEditData:any;
    survey: any;
    openErrorModal: boolean;
    importIds: number[];
    // selectedSubCategory:any;
    // selectedRequestedBy:any;
    // errMssge:string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CreateSurveysController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    apiServicesFilterCallId: string = ""
    apiCategoryListCallId: string = ""
    apiCreateServiceCallId: string = ""
    generateServiceIdCallId: string = ""
    favCallIdToPostId: { [key: string]: string } = {}
    moderatorSearchCallId: string = ""
    requestorSearchCallId: string = ""
    initUsersPagination: any = undefined;
    initRequestorPagination: any = undefined;
    initTaggedComPagination:any=undefined;
    initTaggedProjectPagination:any=undefined;
    initRequestorList: any[] = [];
    initTaggedComList:any[]=[];
    initUsersCallId: string = "";
    initUsersList: any[] = [];
    coinsWalletRequestId: string = "";
    transferCoinsId: string = "";
    importCsvCallId: string = "";
    uniqueTemplateCallId: string = "";
    createTemplateCallId: string = "";
    taggedCommunitiesCallId: string = "";
    getSurveyEditCallId: string = "";
    requestedByCallId: string = "";
    taggedProjectCallId: string = "";
    filterRef?: any;
    path:string = this.props.navigation.history.location.pathname
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            token: '',
            selectedMyTab: 0,
            modal: null,
            loadingServices: false,
            filterQuery: '',
            randomServiceId: '',

            moderatorSearchCallId: "",
            requestorSearchCallId: "",
            taggedComSearchCallId:"",
            moderatorOptions: [],
            requestorOptions: [],
            taggedComOptions:[],
            moderatorQuery: '',
            requestorQuery: '',
            taggedComQuery: '',
            allUserOptions: [],
            allRequestorOptions: [],
            allTaggedComOptions:[],
            memberOptions: [],
            memberRequestorOptions: [],
            memberTaggedComOptions:[],
            memberSearchCallId: "",
            selectedItem: [],
            loadingCorpstageCoins: false,
            corpstageWallet: {},
            totalSelectedCoins: 0,
            coinErrors: [],
            importLoading: false,
            importedUsers: [],
            transferData: [],
            enableBtn: false,
            disableBtn: false,
            totalCoins: 0,
            selectedCountCoins: [],
            template_id: '',
            requested_by: [],
            status: '',
            inactive_date: null,
            start_date: null,
            end_date: null,
            template_name: '',
            survey_title: '',
            description: '',
            survey_time: null,
            reminder_duration: null,
            reminder_type: 0,
            reminder_frequency: false,
            tagged_communities: [],
            tagged_users: [],
            tagged_users_obj: [],
            validEndDate: {},
            toggleDisabled:false,
            openErrorModal: false,
            // getSurveyEditCallId:'',
            // surveyEditData:{},
            survey: [],
            importIds: [],
            taggedProjectPagination: {},
            taggedProjectSearchCallId: "",
            taggedProjectOptions: [],
            taggedProjectQuery: "",
            allTaggedProjectOptions: [],
            tagged_projects: [],
            taggedUserIds:[]
            // selectedSubCategory:[],
            // selectedRequestedBy:[],
            // errMssge:""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.filterRef = React.createRef()
        // Customizable Area End

    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        const authToken = await getStorageData('authToken');
        const id = this.props.navigation.getParam('id')
        const copyUrl = this.props.navigation.history.location.pathname.includes('CopySurveyTemplate')
        if (authToken == null) {
            this.props.navigation.navigate('Home')
        } else {
          this.setState({token: authToken}, () => {
            this.loadInitialUsers(id)
            this.loadRequestedByUsers(id)
            this.onSelectInput(id)
          })
        }
        if (id) {
            this.getSurveyEditCallId = sendAPIRequest(`${configJSON.getSurveyEditEndpoint}/${id}`, {
                method: 'GET',
                headers: { 'token': authToken }
            })
            if (id && copyUrl) { this.getUniqueId() }
            this.getTagdedProject();
        } else {
            this.getUniqueId();
            this.getTagdedProject();
        }
      }
    // Customizable Area End
    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
            let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

            this.showAlert(
                "Change Value",
                "From: " + this.state.txtSavedValue + " To: " + value
            );

            this.setState({ txtSavedValue: value });
        }

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson && responseJson.errors) {
                this.parseApiErrorResponse(responseJson.errors);
                this.parseApiCatchErrorResponse(responseJson.errors);
            } else if (responseJson) {
                switch (apiRequestCallId) {
  case this.initUsersCallId:
    this.handleInitUsersCall(responseJson);
    break;
  case this.requestedByCallId:
    this.handleRequestedByCall(responseJson);
    break;
  case this.taggedCommunitiesCallId:
    this.handleTaggedCommunitiesCall(responseJson);
    break;
  case this.state.moderatorPaginateCallId:
  case this.state.requestorPaginateCallId:
    this.handleModeratorAndRequestorPaginate(responseJson);
    break;
  case this.state.taggedComPaginateCallId:
    this.handleTaggedComPaginate(responseJson);
    break;
  case this.state.memberPaginateCallId:
    this.handleMemberPaginate(responseJson);
    break;
  case this.state.moderatorSearchCallId:
    this.handleModeratorSearchs(responseJson);
    break;
  case this.state.requestorSearchCallId:
    this.handleRequestorSearchfactor(responseJson);
    break;
  case this.state.taggedComSearchCallId:
    this.handleTaggedComSearchfactor(responseJson);
    break;
  case this.state.memberSearchCallId:
    this.handleMemberSearch(responseJson);
    break;
  case this.createTemplateCallId:
    this.handleCreateTemplate(responseJson);
    break;
  case this.coinsWalletRequestId:
    this.handleCoinsWallet(responseJson);
    break;
  case this.transferCoinsId:
    this.handleTransferCoins(responseJson);
    break;
  case this.importCsvCallId:
    this.handleImportCsv(responseJson);
    break;
  case this.uniqueTemplateCallId:
    this.handleUniqueTemplate(responseJson);
    break;
  case this.getSurveyEditCallId:
    this.handleSurveyEditCall(responseJson);
    break;
  case this.taggedProjectCallId:
    this.handleTaggedProjectCall(responseJson);
    break;
  case this.state.taggedProjectSearchCallId:
    this.handleProjectSearchcaCall(responseJson);
    break;
  
  default:
    break;
}
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    handleSurveyTimeChange = (e: any) => {
        this.setState({ survey_time: e.target.value })
    }

    handleDurationChange = (e: any) => {
        this.setState({ reminder_duration: e.target.value })
    }

    totalCoinsCalc = (totalSelectedCoins: any) => {
        const totalCoins = totalSelectedCoins.reduce((total: any, b: any) => {
            return total + b.coins
        }, 0)
        this.setState({ totalCoins: totalCoins })
    }

    isEmpty = (str: any) => (!str?.length);

    hideModal = () => { this.props.navigation.goBack(); }

    handleErrorModal = () => { this.setState({...this.state, openErrorModal: !this.state.openErrorModal }) };

    hideDeficientModal = () => { this.setState({ modal: null }) }

    handleUserSearch = (query: string, page?: number) => {
        const { token } = this.state
        const params = new URLSearchParams()
        query && params.append('query', query)
        page && params.append('page', String(page))
        const url = configJSON.getUsersEndpoint + "?" + params.toString()
        return sendAPIRequest(url, { method: 'GET', headers: { token } })
    }

    handleRequestorUserSearch = (query: string, page?: number) => {
        const { token } = this.state
        const params = new URLSearchParams()
        query && params.append('query', query)
        page && params.append('page', String(page))
        const url = configJSON.getRequestedByEndpoint + "?" + params.toString()
        return sendAPIRequest(url, { method: 'GET', headers: { token } })
    }
    
    handleTaggedComSearch = (query: string, page?: number) => {
        const { token } = this.state
        const params = new URLSearchParams()
        query && params.append('query', query)
        page && params.append('page', String(page))
        const url = configJSON.getTaggedCommunitiesWithPagination + "?" + params.toString()
        return sendAPIRequest(url, { method: 'GET', headers: { token } })
    }

    handleTaggedProjectSearch = (query: string, page?: number) => {
        const { token } = this.state
        const params = new URLSearchParams()
        query && params.append('query', query)
        page && params.append('page', String(page))
        const url = configJSON.getTaggedProject + "?" + params.toString()
        return sendAPIRequest(url, { method: 'GET', headers: { token } })
    }

    paginateModerators = () => {
        if (this.state.moderatorSearchCallId !== '') return
        const { next_page } = this.state.moderatorPagination
        this.setState({ moderatorPaginateCallId: this.handleUserSearch(this.state.moderatorQuery, next_page) })
    }

    paginateRequestors = () => {
        if (this.state.requestorSearchCallId !== '') return
        const { next_page } = this.state.requestorPagination
        this.setState({ requestorPaginateCallId: this.handleRequestorUserSearch(this.state.requestorQuery, next_page) })
    }

    paginateTaggedCom = () => {
        if (this.state.taggedComSearchCallId !== '') return
        const { next_page } = this.state.taggedComPagination
        this.setState({ taggedComPaginateCallId: this.handleTaggedComSearch(this.state.taggedComQuery, next_page) })
    }

    paginateTaggedProject = () => {
        if (this.state.taggedProjectSearchCallId !== '') return
        const { next_page } = this.state.taggedProjectPagination
        this.setState({ taggedProjectPaginateCallId: this.handleTaggedProjectSearch(this.state.taggedProjectQuery, next_page) })
    }


    handleModeratorSearch = (query: string) => {
        if (query)
            this.setState({
                moderatorSearchCallId: this.handleUserSearch(query),
                moderatorQuery: query,
                moderatorPaginateCallId: ''
            })
        else
            this.setState({
                moderatorOptions: this.state.allUserOptions,
                moderatorPagination: this.initUsersPagination,
                moderatorQuery: '',
                moderatorSearchCallId: '',
            })
    }

    handleRequestorSearch = (query: string) => {
        if (query)
            this.setState({
                requestorSearchCallId: this.handleRequestorUserSearch(query),
                requestorQuery: query,
                requestorPaginateCallId: ''
            })
        else
            this.setState({
                requestorOptions: this.state.allRequestorOptions,
                requestorPagination: this.initRequestorPagination,
                requestorQuery: '',
                requestorSearchCallId: '',
            })
    }

    handleTaggedCommunitySearch = (query: string) => {
        if (query)
            this.setState({
                taggedComSearchCallId: this.handleTaggedComSearch(query),
                taggedComQuery: query,
                taggedComPaginateCallId: ''
            })
        else
            this.setState({
                taggedComOptions: this.state.allTaggedComOptions,
                taggedComPagination: this.initTaggedComPagination,
                taggedComQuery: '',
                taggedComSearchCallId: '',
            })
    }

    handleTaggedProjectsSearch = (query: string) => {
        if (query)
            this.setState({
                taggedProjectSearchCallId: this.handleTaggedProjectSearch(query),
                taggedProjectQuery: query,
                taggedProjectPaginateCallId: ''
            })
        else
            this.setState({
                taggedProjectOptions: this.state.allTaggedProjectOptions,
                taggedProjectPagination: this.initTaggedProjectPagination,
                taggedProjectQuery: '',
                taggedProjectSearchCallId: '',
            })
    }

    handleProjectSearchcaCall(responseJson: any) {
        if (!Array.isArray(responseJson.projects.data)){
                        this.setState({
                            taggedProjectSearchCallId: '',
                            taggedProjectOptions: [],
                            taggedProjectPagination: undefined
                        })
                    }
                    else {
                        const taggedProjectOptions = responseJson.projects.data.map((d: any) => {return{...d?.attributes,id:Number(d?.id)}})
                        this.setState({
                            taggedProjectSearchCallId: '',
                            taggedProjectOptions,
                            taggedProjectPagination: responseJson.meta.pagination,
                        })
                    }
    }

    handleTaggedComSearchCall(responseJson: any) {
        if (!Array.isArray(responseJson.communities?.data))
                        this.setState({
                            taggedComSearchCallId: '',
                            taggedComOptions: [],
                            taggedComPagination: undefined
                        })
                    else {
                        const taggedComOptions = responseJson.communities.data.map((d: any) => d.attributes)
                        this.setState({
                            taggedComSearchCallId: '',
                            taggedComOptions,
                            taggedComPagination: responseJson?.meta?.pagination,
                        })
                    }
    }

    handleModeratorClose = () => {
        this.setState({
            moderatorSearchCallId: '',
            moderatorQuery: '',
            moderatorPaginateCallId: '',
            moderatorOptions: this.state.allUserOptions,
            moderatorPagination: this.initUsersPagination,
        })
    }

    handleRequestorClose = () => {
        this.setState({
            requestorSearchCallId: '',
            requestorQuery: '',
            requestorPaginateCallId: '',
            requestorOptions: this.state.allRequestorOptions,
            requestorPagination: this.initRequestorPagination,
        })
    }

    handleTaggedComClose = () => {
        this.setState({
            taggedComSearchCallId: '',
            taggedComQuery: '',
            taggedComPaginateCallId: '',
            taggedComOptions: this.state.allTaggedComOptions,
            taggedComPagination: this.initTaggedComPagination,
        })
    }

    handleTaggedProjectClose = () => {
        this.setState({
            taggedProjectSearchCallId: '',
            taggedProjectQuery: '',
            taggedProjectPaginateCallId: '',
            taggedProjectOptions: this.state.allTaggedProjectOptions,
            taggedProjectPagination: this.initTaggedProjectPagination,
        })
    }

    loadInitialUsers = (id:any) => {
        const { token } = this.state
        this.initUsersCallId = sendAPIRequest(
            `${configJSON.getUsersEndpoint}?survey_id=${id}`,
            { method: 'GET', headers: { token } }
        )

    }

    loadRequestedByUsers = (id:any) => {
        const { token } = this.state
        this.requestedByCallId = sendAPIRequest(`${configJSON.getRequestedByEndpoint}?survey_id=${id}`, { method: 'GET', headers: { token } })
    }

    getTagdedProject = () => {
        const { token } = this.state
        this.taggedProjectCallId = sendAPIRequest(configJSON.getTaggedProject, { method: 'GET', headers: { token } })

    }

    onSelectInput = (_id:any) => {
        const id = this.context.id
        const { token } = this.state
        let bySurveyId=''
        if(!!_id){
            bySurveyId=`&survey_id=${_id}`
        }
        this.taggedCommunitiesCallId = sendAPIRequest(`${configJSON.getTaggedCommunitiesWithPagination}?requested_by_id=${id}${bySurveyId}`, {
            method: 'GET',
            headers: {token}})
    }

    handleDeleteChip=(id:any)=>{
        // const filteredChip=
    }

    filterUnique = (items: any[]) => {
        const hashmap = new Set()
        return items.filter((i: any) => {
            if (hashmap.has(i.id)) return false
            hashmap.add(i.id)
            return hashmap
        })
    }

    handlePurchaseCoins = () => {
        this.props.navigation.navigate("PurchaseCoins");
    }

    validationSchemaNonMandatorySurvey =  yup.object().shape({
      template_name: yup.string().max(100, 'Maximum limit is 100 Characters').label('Template Name').required('Required field is mandatory'),
      survey_title: yup.string().max(100, 'Maximum limit is 100 Characters').label('Survey Title').required('Required field is mandatory'),
      description: yup.string().required('Required field is mandatory').max(1100, "Maximum  limit is 1100 characters"),
      // reminder_frequency:yup.boolean().nullable(),
      // reminder_duration: yup.string()
          // .when('reminder_frequency', {
          //     is: true,
          //     then: yup.string().required(),
          //     // otherwise: yup.number().notRequired(),
          //   }),
      start_date: yup.string().required('Required field is mandatory').nullable(),
      end_date: yup.string().required('Required field is mandatory').nullable(),
  });

    validationSchemaMandatorySurvey = yup.object().shape({
      template_name: yup.string().max(100, 'Maximum limit is 100 Characters').label('Template Name').required('Required field is mandatory'),
      survey_title: yup.string().max(100, 'Maximum limit is 100 Characters').label('Survey Title').required('Required field is mandatory'),
      description: yup.string().required('Required field is mandatory').max(1100, "Maximum  limit is 1100 characters"),
  });
  validationSchema = this.props.navigation.history.location.pathname.includes('MandatorySurveys') ? this.validationSchemaMandatorySurvey : this.validationSchemaNonMandatorySurvey


    handleEnableBtn = async() => {
      const transferData = await getStorageData('transferData')
        if (transferData) {
          this.setState({ enableBtn: true })
        }
    }

    importCsv = (files: File[]) => {
        const file = Array.from(files)[0]
        if (file) {
            const { token } = this.state
            const body = new FormData()
            //@ts-ignore
            body.append('file', file)
            this.importCsvCallId = sendAPIRequest(configJSON.importCsvEndpoint, {
                method: 'POST',
                body,
                headers: { token }
            })
            this.setState({ importLoading: true })
        }
    }

    handleRemoveField = (arrayHelpers: any, us: any, index: number) => {
        arrayHelpers.remove(index)
        const filtered = this.state.importedUsers.filter((item, i) => index !== i)
        this.setState({ importedUsers: filtered })
        const file = document.querySelector('.userFile');
        //@ts-ignore
        file.value = '';

        var node = document.getElementById("csvID");
        const input = document.querySelector('input')
        //@ts-ignore
        const onRemove = this.state.selectedCountCoins.filter((item: any) => item.id !== index)
        const updateId = onRemove.map((item) => {
            if (item.id >= index) {
                item.id = item.id - 1
                return item
            }
            return item
        })
        this.setState({ selectedCountCoins: updateId })
        this.totalCoinsCalc(updateId)
    }

    getUniqueId = () => {
        let url = configJSON.createTemplateUniqueId
        this.uniqueTemplateCallId = sendAPIRequest(url, {
            method: "GET",
            headers: { 'token': this.state.token },
        })
    }

    handleChange = (event: any, values: any) => {
        if (event.target.checked) {
            this.setState({ status: true, openErrorModal: true })
        } else {
            this.setState({ status: false })
        }
    };

    handleFilterMenuOpen = (e: React.MouseEvent) => {
        if (!this.filterRef) return;
        const box = this.filterRef.current.getBoundingClientRect();
        this.setState({
            //   filterMenuPosition: {
            //     top: box.bottom,
            //     left: box.left + 20
            //   }
        })
    }

    startDateChange = (v: any) => {
        this.setState({ start_date: v })
    }
    endDateChange = (v: any) => {
        this.setState({ end_date: v, validEndDate: { maxDate: v ? v : null } })
    }

    handleCreateTemplateSubmit = async (value: any) => {
        if(value.status==="inactive"){
            toast.warn(`Cannot Update Inactive Template`)
        } else{
            let requestBody ={
                "data": {
                    "attributes": {
                        "survey_uniq_id": value.template_id,
                        "template_name": value.template_name,
                        "survey_title": value.survey_title,
                        "description": value.description,
                        "status": (value.status === 'undefined' && value.start_date === new Date()) ? 2 : this.state.status ? 1 : 0,
                        "survey_time": this.state.survey_time,
                        "reminder_type": value.reminder_type,
                        "reminder_duration": this.state.reminder_duration,
                        "inactive_date": value.inactive_date,
                        "start_date": value.start_date,
                        "end_date": value.end_date,
                        "reminder_frequency": value.reminder_frequency,
                        "requested_by_id": value.requested_by,
                        "community_ids": value.tagged_communities,
                        'project_management_ids': value.tagged_projects,
                        "user_ids": value.tagged_users,
                    }
                }
            };
        let editId = this.props.navigation.getParam('id')
        let url, method;
        const { isInCopy, isInEdit } = this.isInCopyOrEditMode(editId, this.props.navigation.history.location.pathname);
        if (isInEdit) {
            url = `${configJSON.getSurveyEditEndpoint}/${editId}`
            method = 'PUT'
        } else if (isInCopy) {
            url = `${configJSON.createSurveyTemplate}`
            //@ts-ignore
            requestBody.data.attributes.id=editId
            method = 'POST'
        }
        const reportingFrameworkID = await getStorageData("reportingFrameworkId");
        const isMandatory = this.props.navigation.history.location.pathname.includes('MandatorySurveys')
        const rFID = isMandatory ? reportingFrameworkID : ''
        const updatedRequestBody = {
          ...requestBody,
          data: {
            ...requestBody.data,
            attributes: {
              ...requestBody.data.attributes,
              mandatory: isMandatory,
              reporting_framework_id: rFID
            }
          }
        };
        this.createTemplateCallId = sendAPIRequest(String(url), {
            //@ts-ignore
            method: method,
            headers: {
                token: await getStorageData('authToken'),
                'content-type': 'application/json'
            },
            body: updatedRequestBody,
        })
    }
    }

    reminderTypeChange = (e: any) => {
        this.setState({ reminder_type: e.target.value })
    }

handleInitUsersCall(responseJson:any) {
  if (!Array.isArray(responseJson.data)) return;
  const allUserOptions = responseJson.data.map((d:any) => d.attributes);
  const pagination = responseJson.meta.pagination;
  this.initUsersList = allUserOptions;
  this.initUsersPagination = pagination;
  this.setState({
    moderatorOptions: allUserOptions,
    memberOptions: allUserOptions,
    allUserOptions,
    memberPagination: pagination,
    moderatorPagination: pagination,
  });
}

handleRequestedByCall(responseJson:any) {
    if (!Array.isArray(responseJson.data)) return;
    const allRequestorOptions = responseJson.data.map((d:any) => d.attributes);
    const pagination = responseJson?.meta?.pagination;
    this.initRequestorList = allRequestorOptions;
    this.initRequestorPagination = pagination;
    this.setState({
      requestorOptions: allRequestorOptions,
      memberRequestorOptions: allRequestorOptions,
      allRequestorOptions,
      requestorMemberPagination: pagination,
      requestorPagination: pagination,
      moderatorOptions: allRequestorOptions,
    });
  }
  
handleTaggedCommunitiesCall(responseJson:any) {
    if (responseJson) {
      const allTaggedComOptions = responseJson.communities?.data?.map((item:any) => item?.attributes);
      const pagination = responseJson?.meta?.pagination;
      this.initTaggedComList = allTaggedComOptions;
      this.initTaggedComPagination = pagination;
      this.setState({
        taggedComOptions: allTaggedComOptions,
        memberTaggedComOptions: allTaggedComOptions,
        allTaggedComOptions,
        taggedComMemberPagination: pagination,
        taggedComPagination: pagination,
      });
    }
  }
  
handleModeratorAndRequestorPaginate(responseJson:any) {
    if (Array.isArray(responseJson.data)) {
      const options = responseJson.data.map((d:any) => d.attributes);
      const moderatorOptions = this.filterUnique(this.state.moderatorOptions.concat(options));
      const requestorOptions = this.filterUnique(this.state.requestorOptions.concat(options));
      this.setState({
        moderatorOptions,
        requestorOptions,
        moderatorPagination: responseJson.meta.pagination,
        requestorPagination: responseJson.meta.pagination,
      });
    }
  }
  
handleTaggedComPaginate(responseJson:any) {
    if (Array.isArray(responseJson.communities.data)) {
      const opted = responseJson.communities.data.map((e:any) => e.attributes);
      const taggedComOptions = this.filterUnique(this.state.taggedComOptions.concat(opted));
      this.setState({
        taggedComOptions,
        taggedComPagination: responseJson?.meta?.pagination,
      });
    }
  }
  
handleMemberPaginate(responseJson:any) {
    if (Array.isArray(responseJson.data)) {
      const options = responseJson.data.map((d:any) => d.attributes);
      const memberOptions = this.filterUnique(this.state.memberOptions.concat(options));
      const memberRequestorOptions = this.filterUnique(this.state.memberRequestorOptions.concat(options));
      const memberTaggedComOptions = this.filterUnique(this.state.memberTaggedComOptions.concat(options));
      this.setState({
        memberOptions,
        memberRequestorOptions,
        memberTaggedComOptions,
        memberPagination: responseJson?.meta?.pagination,
        requestorMemberPagination: responseJson?.meta?.pagination,
        taggedComMemberPagination: responseJson?.meta?.pagination,
      });
    }
  }
  
handleModeratorSearchs(responseJson:any) {
    if (!Array.isArray(responseJson.data))
      this.setState({
        moderatorSearchCallId: "",
        moderatorOptions: [],
        moderatorPagination: undefined,
      });
    else {
      const moderatorOptions = responseJson.data.map((d:any) => d.attributes);
      this.setState({
        moderatorSearchCallId: "",
        moderatorOptions,
        moderatorPagination: responseJson?.meta?.pagination,
      });
    }
  }
  
  handleRequestorSearchfactor(responseJson:any) {
    if (!Array.isArray(responseJson.data))
      this.setState({
        requestorSearchCallId: "",
        requestorOptions: [],
        requestorPagination: undefined,
      });
    else {
      const requestorOptions = responseJson.data.map((d:any) => d.attributes);
      this.setState({
        requestorSearchCallId: "",
        requestorOptions,
        requestorPagination: responseJson.meta.pagination,
      });
    }
  }
  
handleTaggedComSearchfactor(responseJson:any) {
    const responseData = responseJson.communities;
    console.log("tagged communities ", responseData);
    if (!Array.isArray(responseData.data))
      this.setState({
        taggedComSearchCallId: "",
        taggedComOptions: [],
        taggedComPagination: undefined,
      });
    else {
      const taggedComOptions = responseData.data.map((d:any) => d.attributes);
      this.setState({
        taggedComSearchCallId: "",
        taggedComOptions,
        taggedComPagination: responseData?.meta?.pagination,
      });
    }
  }
  
handleMemberSearch(responseJson:any) {
    if (!Array.isArray(responseJson.data))
      this.setState({
        memberSearchCallId: "",
        memberOptions: [],
        memberRequestorOptions: [],
        memberTaggedComOptions: [],
        memberPagination: undefined,
        requestorMemberPagination: undefined,
        taggedComMemberPagination: undefined,
      });
    else {
      const memberOptions = responseJson.data.map((d:any) => d.attributes);
      const memberRequestorOptions = responseJson.data.map((d:any) => d.attributes);
      const memberTaggedComOptions = responseJson.data.map((d:any) => d.attributes);
      this.setState({
        memberSearchCallId: "",
        memberOptions,
        memberRequestorOptions,
        memberTaggedComOptions,
        memberPagination: responseJson?.meta?.pagination,
        requestorMemberPagination: responseJson?.meta?.pagination,
        taggedComMemberPagination: responseJson?.meta?.pagination,
      });
    }
  }
  
handleCreateTemplate(responseJson:any) {
  const isMandatorySurvey = this.props.navigation.history.location.pathname.includes('MandatorySurveys')
    if (responseJson) {
      this.navigationMessage(isMandatorySurvey ? "MandatorySurveys" : "Surveys",this.props,{"navigated":true})
    }
  }
  
handleCoinsWallet(responseJson:any) {
    if (responseJson?.user_wallet) {
      const corpstageWallet = responseJson?.user_wallet;
      this.setState({ corpstageWallet });
    }
    this.setState({ loadingCorpstageCoins: false });
  }
  
handleTransferCoins(responseJson:any) {
    if (responseJson.Message) {
      toast.success(responseJson.Message);
      window.location.href = "/CorpsStageCoins";
      setTimeout(() => {
        this.setState({ disableBtn: false });
      }, 1000);
    }
    setTimeout(() => {
      this.setState({ disableBtn: false });
    }, 1000);
  }
  
handleImportCsv(responseJson:any) {
    if (Array.isArray(responseJson?.data)) {
      const importIds = responseJson.data.map((d:any) => d.attributes.id);
      const emailErrors = responseJson.meta.invalid_emails;
      const importedUsers = responseJson.data.map((d:any) => d.attributes);
  
      let moderatorOptions = [...importedUsers, ...this.state.memberOptions];
  
      const alreadyPresentCount1 = this.state.taggedUserIds.filter((element) =>
        importIds.includes(element)
      ).length;
      const maxAllowedMergeCount =
        this.state.taggedUserIds.length === 0
          ? importIds?.length
          : importIds?.length - alreadyPresentCount1;
      const tagged_users_ids = responseJson.data.map((d:any) => d.attributes.id);
      const tempuser = this.state.taggedUserIds;
      let taggedUserIds = Array.from(new Set([...tempuser, ...tagged_users_ids]));
      if (emailErrors?.length > 0)
        toast.warn(`Could not import ${emailErrors?.length} emails, invalid or not found.`);
      if (alreadyPresentCount1 != importIds?.length)
        toast.success(`${maxAllowedMergeCount} users imported from CSV`);
      if (alreadyPresentCount1) toast.success(`${alreadyPresentCount1} users already Presented in tagged list`);
      this.setState({
        importIds,
        importLoading: false,
        importedUsers,
        moderatorOptions,
        taggedUserIds,
      });
    }
  }
  
handleUniqueTemplate(responseJson:any) {
    if (responseJson?.survey_uniq_id) {
      this.setState({ template_id: responseJson?.survey_uniq_id });
    }
  }
  
handleSurveyEditCall(responseJson:any) {
    const templateid = responseJson?.data?.attributes?.survey_uniq_id;
    const requested_by = responseJson?.data?.attributes?.requested_by?.id;
    const tagged_users = responseJson?.data?.attributes?.tagged_users.data.map((it:any) => Number(it.id));
    const tagged_users_obj = responseJson?.data?.attributes?.tagged_users.data;
    const taggged_communities = responseJson?.data?.attributes?.tagged_communities.data.map(
      (it:any) => it?.attributes?.id
    );
    const tagged_projects = responseJson?.data?.attributes?.tagged_project_managements.data.map(
      (it:any) => Number(it?.id)
    );
  
    const receivedDate = responseJson.data.attributes.start_date;
    const formatedDate = receivedDate.split('/').reverse().join('-');
    const textDate = new Date(formatedDate).toString();
    if (this.props.navigation.history.location.pathname.includes('CopySurveyTemplate') && responseJson?.data?.attributes?.id) {
      this.setState({
        survey: {
          ...responseJson?.data?.attributes,
          start_date: '',
          end_date: '',
          status: '',
          inactive_date: '',
          reminder_frequency: false,
          reminder_duration: '',
          reminder_type: 0,
          tagged_communities: [],
          tagged_projects: [],
        },
        template_id: this.state.template_id,
        requested_by: [],
        tagged_users: [],
        reminder_type: responseJson.data.attributes.reminder_type_id || 0,
        tagged_communities: [],
        tagged_projects: [],
      });
    } else {
      this.setState({
        survey: responseJson?.data?.attributes,
        start_date: textDate,
        end_date: new Date(responseJson.data.attributes.end_date.split('/').reverse().join('-')).toString(),
        template_id: templateid,
        requested_by: [String(requested_by)],
        tagged_users,
        tagged_users_obj: tagged_users_obj,
      });
      this.setState({
        status: responseJson.data.attributes.status === 'inactive' ? true : false,
        survey_time: responseJson.data.attributes.survey_time,
        reminder_duration: responseJson.data.attributes.reminder_duration,
        reminder_type: responseJson.data.attributes.reminder_type_id || 0,
        tagged_communities: taggged_communities,
        tagged_projects,
      });
    }
  }
  
 handleTaggedProjectCall(responseJson:any) {
    const allTaggedProjectOptions = responseJson.projects?.data?.map((item:any) => {
      return { ...item?.attributes, id: Number(item?.id) };
    });
    const pagination = responseJson?.meta?.pagination;
    this.initTaggedComList = allTaggedProjectOptions;
    this.initTaggedComPagination = pagination;
    this.setState({
      taggedProjectOptions: allTaggedProjectOptions,
      memberTaggedComOptions: allTaggedProjectOptions,
      allTaggedProjectOptions,
      taggedProjectMemberPagination: pagination,
      taggedProjectPagination: pagination,
    });
  }
  // Copy data from web file to controller file to resolved code smell

  getInitialValuesData(state:any, context:any, isEditPage:boolean, getCopyPath:boolean) {
    const isAllowed = isEditPage && !getCopyPath
    return {
        template_id: state.template_id || '',
        reminder_type: state.reminder_type || 0,
        template_name: state.survey.template_name || '',
        survey_title: state.survey.survey_title || '',
        description: state.survey.description || '',
        requested_by: state.requested_by || [Number(context.id)] || [],
        tagged_communities: state?.survey?.tagged_communities?.data?.map((item:any) => item?.attributes?.id) || state.survey.tagged_communities || [],
        tagged_projects: state.tagged_projects || [],
        tagged_users: state.tagged_users || [],
        status: state.survey.status,
        inactive_date: state.inactive_date || null,
        start_date: isAllowed ? state?.start_date : state.survey.start_date || null,
        end_date: isAllowed ? state.end_date : state.survey.end_date || null,
        survey_time: state.survey.survey_time,
        reminder_duration: state.survey.reminder_duration,
        reminder_frequency: state.survey.reminder_frequency,
    };
  }
  navigationMessage = (route: string, props: any, payload?: any) => {
    const navMessage = new Message(getName(MessageEnum.NavigationMessage));
    navMessage.addData(getName(MessageEnum.NavigationTargetMessage), route);
  
    navMessage.addData(getName(MessageEnum.NavigationPropsMessage), props);
    if (payload) {
      const raisePayloadDataMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raisePayloadDataMessage.addData(getName(MessageEnum.ReportingFrameworksNavData), payload);
      navMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raisePayloadDataMessage);
    }
  
    this.send(navMessage);
  }
  isInCopyOrEditMode(editId:any, pathname:string) {    
    const isInCopy = !editId || (editId && pathname.includes('CopySurveyTemplate'));
    const isInEdit = editId && pathname.includes('EditSurveys');
  
    return { isInCopy, isInEdit };
  }
  determineDisabledStatus(survey:any) {
    const disableMS = survey.status === "inactive" || survey?.mandatory;
    const disableFre = survey.status === "inactive" || survey?.mandatory;
    const disableStatus = survey.status === "inactive" 
  
    return { disableMS, disableFre, disableStatus };
  }
    // Customizable Area End

}

